<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom v-if="!hideMobile">
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="relative flex items-center justify-center w-10 h-10 my-2 rounded-lg hover:text-slate-700 text-slate-700 dark:text-slate-100 hover:bg-mila-peach100 dark:hover:bg-slate-700 dark:hover:text-slate-100"
      :class="{
        'dark:bg-slate-800 text-mila-peach bg-mila-peach100':
          isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <span v-if="name === 'MY_CONTENT'">
        <img class="w-5 h-5" v-if="isActive || isChildMenuActive" src="~dashboard/assets/images/brain-active.svg"/>
        <img
          v-else
          src="~dashboard/assets/images/brain.svg"
          alt=""
          class="w-5 h-5"
        />
      </span>
      <fluent-icon
        v-if="name !== 'MY_CONTENT'"
        :icon="icon"
        :class="{
          'text-mila-peach': isActive || isChildMenuActive,
        }"
      />
      <span class="sr-only">{{ name }}</span>
      <span
        v-if="count"
        class="absolute bg-yellow-500 text-black-900 -top-1 -right-1"
      >
        {{ count }}
      </span>
    </a>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hideMobile() {
      const mobileBlocklist = ['SETTINGS', 'HELP_CENTER.TITLE', 'CAMPAIGNS', 'MY_CONTENT', 'SETUP'];
      return mobileBlocklist.includes(this.name) && screen.width <= 760;
    }
  },
};
</script>
