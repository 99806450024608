/* global axios */
import ApiClient from './ApiClient';

class Environment extends ApiClient {
  constructor() {
    super('environment', { accountScoped: false });
  }

  get() {
    return axios.get(`${this.url}/get_env`);
  }
}

export default new Environment();
