export const LOCAL_STORAGE_KEYS = {
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  MESSAGE_REPLY_TO: 'messageReplyTo',
  SHOW_NOTIFICATION_BANNER: 'showNotificationBanner',
  AGENT_BOT_INBOX_ID: 'agentBotInboxId',
  SHOW_ONBOARDING_MODAL: 'showOnboardingModal',
};
