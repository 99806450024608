import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AgentBotsAPI from '../../api/agentBots';
import InboxesAPI from '../../api/inboxes';
import { throwErrorMessage } from '../utils/api';
import { LocalStorage } from '../../../shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from '../../../dashboard/constants/localStorage';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isFetchingAgentBot: false,
    isSettingAgentBot: false,
    isDisconnecting: false,
  },
  agentBotInbox: {},
  currentBot: {},
  agentBotInboxId: null,
};

export const getters = {
  getBots($state) {
    return $state.records;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBot: $state => botId => {
    const [bot] = $state.records.filter(record => record.id === Number(botId));
    return bot || {};
  },
  getActiveAgentBot: $state => inboxId => {
    const associatedAgentBotId = $state.agentBotInbox[Number(inboxId)];
    return getters.getBot($state)(associatedAgentBotId);
  },
  getCurrentBot: $state => {
    return $state.records.find(bot => !!bot.external_id);
  },
  getAgentBotInboxId: $state => {
    return $state.agentBotInboxId;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetching: true });
    try {
      const response = await AgentBotsAPI.get();
      commit(types.SET_AGENT_BOTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, { shop, shopify_access_token }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isCreating: true });
    try {
      const response = await AgentBotsAPI.create({
        shop,
        shopify_access_token,
      });

      // disallow create bot if external_id is missing
      if (!response.data.external_id) {
        throw new Error('Bot creation failed, external_id probably missing');
      }

      commit(types.ADD_AGENT_BOT, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...agentBotObj }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AgentBotsAPI.update(id, agentBotObj);
      commit(types.EDIT_AGENT_BOT, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isDeleting: true });
    try {
      await AgentBotsAPI.delete(id);
      commit(types.DELETE_AGENT_BOT, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await AgentBotsAPI.show(id);
      commit(types.SET_CURRENT_BOT, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingItem: false });
    }
  },
  fetchAgentBotInboxId: async ({ commit }) => {
    const inboxId = LocalStorage.get(LOCAL_STORAGE_KEYS.AGENT_BOT_INBOX_ID);
    if (inboxId) {
      commit(types.SET_AGENT_BOT_INBOX_ID, inboxId);
    }
  },
  fetchAgentBotInbox: async ({ commit }, inboxId) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingAgentBot: true });
    try {
      const { data } = await InboxesAPI.getAgentBot(inboxId);
      const { agent_bot: agentBot = {} } = data || {};
      commit(types.SET_AGENT_BOT_INBOX, { agentBotId: agentBot.id, inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingAgentBot: false });
    }
  },

  setAgentBotInbox: async ({ commit }, { inboxId, botId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isSettingAgentBot: true });
    try {
      await InboxesAPI.setAgentBot(inboxId, botId);
      // create inbox
      commit(types.SET_AGENT_BOT_INBOX, { agentBotId: botId, inboxId });
      // get inboxId in state
      commit(types.SET_AGENT_BOT_INBOX_ID, inboxId);
      // save it in local storage so its persistent
      LocalStorage.set(LOCAL_STORAGE_KEYS.AGENT_BOT_INBOX_ID, inboxId);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isSettingAgentBot: false });
    }
  },

  disconnectBot: async ({ commit }, { inboxId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isDisconnecting: true });
    try {
      await InboxesAPI.setAgentBot(inboxId, null);
      commit(types.SET_AGENT_BOT_INBOX, { agentBotId: '', inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isDisconnecting: false });
    }
  },
};

export const mutations = {
  [types.SET_AGENT_BOT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_AGENT_BOT]: MutationHelpers.setSingleRecord,
  [types.SET_AGENT_BOTS]: MutationHelpers.set,
  [types.EDIT_AGENT_BOT]: MutationHelpers.update,
  [types.DELETE_AGENT_BOT]: MutationHelpers.destroy,
  [types.SET_AGENT_BOT_INBOX]($state, { agentBotId, inboxId }) {
    Vue.set($state.agentBotInbox, inboxId, agentBotId);
  },
  [types.SET_CURRENT_BOT]($state, bot) {
    $state.currentBot = bot;
  },
  [types.SET_AGENT_BOT_INBOX_ID]($state, inboxId) {
    $state.agentBotInboxId = inboxId;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
