import Vue from 'vue';
import * as types from '../mutation-types';

const state = {
  currentPage: {
    unread: 0,
    ongoing: 0,
    resolved: 0,
    appliedFilters: 0,
  },
  hasEndReached: {
    unread: false,
    ongoing: false,
    resolved: false,
  },
};

export const getters = {
  getHasEndReached: $state => filter => {
    return $state.hasEndReached[filter];
  },
  getCurrentPageFilter: $state => filter => {
    return $state.currentPage[filter];
  },
  getCurrentPage: $state => {
    return $state.currentPage;
  },
};

export const actions = {
  setCurrentPage({ commit }, { filter, page }) {
    commit(types.default.SET_CURRENT_PAGE, { filter, page });
  },
  setEndReached({ commit }, { filter }) {
    commit(types.default.SET_CONVERSATION_END_REACHED, { filter });
  },
  reset({ commit }) {
    commit(types.default.CLEAR_CONVERSATION_PAGE);
  },
};

export const mutations = {
  [types.default.SET_CURRENT_PAGE]: ($state, { filter, page }) => {
    Vue.set($state.currentPage, filter, page);
  },
  [types.default.SET_CONVERSATION_END_REACHED]: ($state, { filter }) => {
    // if (filter === 'all') {
    //   Vue.set($state.hasEndReached, 'unassigned', true);
    //   Vue.set($state.hasEndReached, 'me', true);
    // }
    Vue.set($state.hasEndReached, filter, true);
  },
  [types.default.CLEAR_CONVERSATION_PAGE]: $state => {
    $state.currentPage = {
      unread: 0,
      ongoing: 0,
      resolved: 0,
      appliedFilters: 0,
    };

    $state.hasEndReached = {
      unread: false,
      ongoing: false,
      resolved: false,
      appliedFilters: false,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
