<template>
  <div
    v-if="!authUIFlags.isFetching"
    id="app"
    class="flex-grow-0 w-full h-full min-h-0 app-wrapper"
    :class="{ 'app-rtl--wrapper': isRTLView }"
    :dir="isRTLView ? 'rtl' : 'ltr'"
  >
    <update-banner :latest-chatwoot-version="latestChatwootVersion" />
    <template v-if="currentAccountId">
      <pending-email-verification-banner />
      <payment-pending-banner />
      <upgrade-banner />
    </template>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <add-account-modal
      :show="showAddAccountModal"
      :has-accounts="hasAccounts"
    />
    <woot-snackbar-box />
    <network-notification />
  </div>
  <loading-state v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import AddAccountModal from '../dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import LoadingState from './components/widgets/LoadingState.vue';
import NetworkNotification from './components/NetworkNotification.vue';
import UpdateBanner from './components/app/UpdateBanner.vue';
import UpgradeBanner from './components/app/UpgradeBanner.vue';
import PaymentPendingBanner from './components/app/PaymentPendingBanner.vue';
import PendingEmailVerificationBanner from './components/app/PendingEmailVerificationBanner.vue';
import vueActionCable from './helper/actionCable';
import WootSnackbarBox from './components/SnackbarContainer.vue';
import rtlMixin from 'shared/mixins/rtlMixin';
import { setColorTheme } from './helper/themeHelper';
import {
  registerSubscription,
  verifyServiceWorkerExistence,
} from './helper/pushHelper';

import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  name: 'App',

  components: {
    AddAccountModal,
    LoadingState,
    NetworkNotification,
    UpdateBanner,
    PaymentPendingBanner,
    WootSnackbarBox,
    UpgradeBanner,
    PendingEmailVerificationBanner,
  },

  mixins: [rtlMixin],

  data() {
    return {
      showAddAccountModal: false,
      latestChatwootVersion: null,
    };
  },

  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      authUIFlags: 'getAuthUIFlags',
      accountUIFlags: 'accounts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
      uiFlags: 'agentBots/getUIFlags',
      currentAccount: 'getCurrentAccount',
      agentBot: 'agentBots/getCurrentBot',
      agentList: 'agents/getAgents',
    }),
    hasAccounts() {
      const { accounts = [] } = this.currentUser || {};
      return accounts.length > 0;
    },
  },

  watch: {
    currentUser() {
      if (!this.hasAccounts) {
        this.showAddAccountModal = true;
      }
    },
    currentAccountId() {
      if (this.currentAccountId) {
        this.initializeAccount();
      }
    },
  },
  mounted() {
    this.initializeColorTheme();
    this.listenToThemeChanges();
    this.setLocale(window.chatwootConfig.selectedLocale);
  },
  methods: {
    initializeColorTheme() {
      setColorTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
    },
    listenToThemeChanges() {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      mql.onchange = e => setColorTheme(e.matches);
    },
    setLocale(locale) {
      this.$root.$i18n.locale = locale;
    },
    async initializeAccount() {
      await this.$store.dispatch('accounts/get');

      this.$store.dispatch('setActiveAccount', {
        accountId: this.currentAccountId,
      });

      const {
        locale,
        latest_chatwoot_version: latestChatwootVersion,
        has_created_bot,
      } = this.getAccount(this.currentAccountId);

      const { pubsub_token: pubsubToken } = this.currentUser || {};
      this.setLocale(locale);
      this.updateRTLDirectionView(locale);
      this.latestChatwootVersion = latestChatwootVersion;
      vueActionCable.init(pubsubToken);
      await this.$store.dispatch('accounts/getOnboardingInfo');
      verifyServiceWorkerExistence(registration =>
        registration.pushManager.getSubscription().then(subscription => {
          if (subscription) {
            registerSubscription();
          }
        })
      );

      if (!has_created_bot) {
        this.startCreatingBot();
      }
    },
    async startCreatingBot() {
      await this.updateShopifyData();
      try {
        const { accessToken, shop } = this.$route.query;
        let shopUrl = shop || this.currentUser.accounts[0].shop;
        const shopName = shopUrl.split('.')[0];
        console.log(
          'Validation: ',
          shopName,
          this.currentUser.accounts[0].shopify_storefront_token,
          accessToken || this.currentUser.accounts[0].shopify_access_token
        );
        const createdBot = await this.$store.dispatch('agentBots/create', {
          shop: shopName,
          shopify_storefront_token:
            this.currentUser.accounts[0].shopify_storefront_token,
          shopify_access_token:
            accessToken ||
            this.currentUser.accounts[0].shopify_access_token ||
            '',
        });

        // create inbox here
        const inbox = await this.$store.dispatch(
          'inboxes/createWebsiteChannel',
          {
            name: this.currentAccount.name,
            greeting_enabled: false,
            enable_email_collect: false,
            csat_survey_enabled: true,
            greeting_message: 'Hello, how can I help you?',
            channel: {
              type: 'web_widget',
              website_url: 'https://mila.com',
              widget_color: '#E76C6C',
              welcome_title: 'Chat with us',
              welcome_tagline: 'Ask me anything',
              selectedFeatureFlags: [
                'use_inbox_avatar_for_bot',
                'emoji_picker',
                'attachments',
                'end_conversation',
              ],
            },
          }
        );
        // set agent inbox based on the created inbox above
        await this.$store.dispatch('agentBots/setAgentBotInbox', {
          inboxId: inbox.id,
          botId: createdBot.id,
        });

        // make the current user (administrator) an 'agent' of the newly created inbox
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: inbox.id,
          agentList: [this.currentUser.id],
        });

        // turn has_created_bot to true
        await this.$store.dispatch('accounts/update', {
          has_created_bot: true,
        });

        // Reload after creating bot to reflect changes
        // TODO: Find a better way to reflect changes without reloading
        // Temporarily disabled due to conflict with new onboarding flow
        // window.location.reload();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in creating bot', error);
        window.bus.$emit(BUS_EVENTS.SHOW_TOAST, {
          title: 'Something went wrong',
          type: 'error',
          message: {
            template: `<div>Something went wrong, please try again. If you need further assistance, please contact Support.</div>`,
          },
        });
      }
    },
    async updateShopifyData() {
      const { accessToken, shop } = this.$route.query;
      if (accessToken && shop) {
        await this.$store.dispatch('accounts/update', {
          shop: shop,
          shopify_access_token: accessToken,
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import './assets/scss/app';
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
