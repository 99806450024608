import posthog from "posthog-js";

export default {
  install(Vue, options) {
    Vue.prototype.$posthog = posthog.init(
      "phc_pP9eawqvgfx5MUe8oB2k2UJKzlB9xVGs5Ku7unvVTXl",
      {
        api_host: "https://us.i.posthog.com",
        person_profiles: 'identified_only',
        capture_pageview: false,
        autocapture: false,
      }
    );
  }
};
