<!-- eslint-disable vue/no-mutating-props -->
<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />
    <div class="modal-footer delete-item">
      <div
        class="bg-white text-supa-primary action-button px-4 py-2 rounded-md cursor-pointer action-button border border-supa-neutral80"
        @click="onClose"
      >
        {{ rejectText }}
      </div>
      <div
        class="bg-supa-red text-white action-button px-4 py-2 rounded-md cursor-pointer"
        @click="onConfirm"
      >
        {{ confirmText }}
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
.action-button {
  max-width: var(--space-giga);
}
</style>
