<template>
  <banner
    v-if="shouldShowBanner"
    color-scheme="primary"
    :banner-message="bannerMessage"
    :action-button-label="actionButtonMessage"
    :banner-title="bannerTitle"
    :is-pending-email-verification="true"
    action-button-icon="mail"
    has-action-button
    @click="resendVerificationEmail"
  />
</template>

<script>
import Banner from 'dashboard/components/ui/Banner.vue';
import { mapGetters } from 'vuex';
import accountMixin from 'dashboard/mixins/account';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: { Banner },
  mixins: [accountMixin, alertMixin],
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      account: 'getCurrentAccount',
    }),
    bannerMessage() {
      return `Please click on the link in the verification email sent to ${this.currentUser.email}.`;
    },
    bannerTitle() {
      return this.$t('APP_GLOBAL.EMAIL_VERIFICATION_TITLE');
    },
    actionButtonMessage() {
      return this.$t('APP_GLOBAL.RESEND_VERIFICATION_MAIL');
    },
    shouldShowBanner() {
      return !this.currentUser.confirmed;
    },
  },
  mounted() {},
  methods: {
    resendVerificationEmail() {
      this.$store.dispatch('resendConfirmation');
      this.showAlert(this.$t('APP_GLOBAL.EMAIL_VERIFICATION_SENT'));
    },
  },
};
</script>
