/* global axios */
import ApiClient from './ApiClient';

class ShopifyTheme extends ApiClient {
  constructor() {
    super('shopify_theme', { accountScoped: false });
  }

  get(apiUrl, accessToken) {
    return axios.get(
      `${this.url}/get_active_theme?api_url=${apiUrl}&access_token=${accessToken}`
    );
  }
}

export default new ShopifyTheme();
