import VueRouter from 'vue-router';
import Vue from 'vue';

import { frontendURL } from '../helper/URLHelper';
import dashboard from './dashboard/dashboard.routes';
import store from '../store';
import {
  validateLoggedInRoutes,
  isAConversationRoute,
} from '../helper/routeHelpers';
import AnalyticsHelper from '../helper/AnalyticsHelper';
import { LocalStorage } from '../../shared/helpers/localStorage';

const routes = [...dashboard.routes];
const botRoutesName = [
  'home_inbox_new',
  'home_inbox_preview',
  'home_inbox_publish',
];

window.roleWiseRoutes = {
  agent: [],
  administrator: [],
};

// generateRoleWiseRoute - updates window object with agent/admin route
const generateRoleWiseRoute = route => {
  route.forEach(element => {
    if (element.children) {
      generateRoleWiseRoute(element.children);
    }
    if (element.roles) {
      element.roles.forEach(roleEl => {
        window.roleWiseRoutes[roleEl].push(element.name);
      });
    }
  });
};
// Create a object of routes
// accessible by each role.Super Admin Console
// returns an object with roles as keys and routeArr as values
generateRoleWiseRoute(routes);

export const router = new VueRouter({ mode: 'history', routes });

export const validateAuthenticateRoutePermission = (to, next, { getters }, from) => {
  const { isLoggedIn, getCurrentUser: user } = getters;

  if (!isLoggedIn) {
    window.location = '/app/login';
    return '/app/login';
  }

  if (!to.name) {
    return next(frontendURL(`accounts/${user.account_id}/new-inbox`));
  }

  const nextRoute = validateLoggedInRoutes(
    to,
    getters.getCurrentUser,
    window.roleWiseRoutes
  );
  return nextRoute ? next(frontendURL(nextRoute)) : next();
};

export const initalizeRouter = () => {
  const isMobile = screen.width <= 760 ? true : false
  router.beforeEach((to, from, next) => {
    AnalyticsHelper.page(to.name || '', {
      path: to.path,
      name: to.name,
    });

    store.dispatch('setUser').then(() => {
      const { isLoggedIn, getCurrentUser: user } = store.getters;
      if (isLoggedIn) {
        // if (isMobile && to.path !== `/app/accounts/${user.account_id}/dashboard`) {
        //   return next(frontendURL(`accounts/${user.account_id}/dashboard`));
        // }
        // Attempt to redirect to lastAccessedRoute only if not heading to login
        if (
          to.path !== '/app/login' &&
          from.path === '/' &&
          !isAConversationRoute
        ) {
          const lastAccessedRoute = LocalStorage.get('lastAccessedRoute');
          if (lastAccessedRoute && lastAccessedRoute !== to.fullPath) {
            // Ensure user has permission for lastAccessedRoute here
            // If permission check passes:
            LocalStorage.remove('lastAccessedRoute');
            return next(lastAccessedRoute);
          }
        }
      } else if (to.path !== '/app/login') {
        // Redirect to login if not logged in and not already heading to login
        return next('/app/login');
      }

      return validateAuthenticateRoutePermission(to, next, store, from);
    });
  });

  router.afterEach(to => {
    if (botRoutesName.includes(to.name)) {
      LocalStorage.set('lastAccessedRoute', to.fullPath);
    }
    Vue.prototype.$posthog.capture('$pageview', {
      $current_url: to.fullPath,
    });
  });
};

export default router;
