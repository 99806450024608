import Vue from 'vue';
import * as types from '../mutation-types';
import environment from '../../api/environment';
import shopifyTheme from '../../api/shopifyTheme';

const state = {
  record: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getEnv: $state => {
    return $state.record;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_ENVIRONMENT_VARIABLES_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await environment.get();
      commit(types.default.SET_ENVIRONMENT_VARIABLES, response.data);
      commit(types.default.SET_ENVIRONMENT_VARIABLES_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_ENVIRONMENT_VARIABLES_UI_FLAG, {
        isFetching: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_ENVIRONMENT_VARIABLES_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_ENVIRONMENT_VARIABLES]: ($state, data) => {
    Vue.set($state, 'record', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
