import webChannel from '../../api/channel/webChannel';
import inboxes from '../../api/inboxes';
import * as types from '../mutation-types';
import Vue from 'vue';
export const state = {
  files: [],
  questions: [],
  text: '',
  savedText: '',
  savedQuestions: [],
  isWidgetEnabled: true,
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isUploading: false,
    isDoneUploading: false,
  },
};

export const getters = {
  getQuestions($state) {
    return $state.questions;
  },
  getSavedQuestions($state) {
    return $state.saveQuestions;
  },
  getFiles($state) {
    return $state.files;
  },
  getText($state) {
    return $state.text;
  },
  getSavedText($state) {
    return $state.savedText;
  },

  getUIFlags($state) {
    return $state.uiFlags;
  },
  getIsWidgetEnabled($state) {
    return $state.isWidgetEnabled;
  },
};

export const actions = {
  fetchContent: async ({ commit }, params) => {
    const response = await inboxes.getDocuments(params.inboxId, params.botId);
    commit(types.default.SET_FILES, response.data.documents);
  },
  setQuestionChanged: async ({ commit }, params) => {
    commit(types.default.SET_QUESTION_CHANGED, params);
  },
  addQuestion: async ({ commit }, params) => {
    commit(types.default.ADD_QUESTION, params);
  },
  // eslint-disable-next-line no-shadow
  updateQuestion: async ({ commit, state }, params) => {
    try {
      commit(types.default.UPDATE_QUESTION, params);
      const data = JSON.parse(JSON.stringify(state.questions));
      const index = data.findIndex(item => item.id === params.id);
      if (index !== -1) {
        Vue.set(data, index, {
          ...data[index],
          ...params,
        });
      } else {
        data.push({ ...params });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  },
  setQuestions: async ({ commit }, params) => {
    try {
      commit(types.default.SET_QUESTIONS, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  saveQuestions: async ({ commit }, params) => {
    try {
      commit(types.default.SAVE_QUESTIONS, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  removeQuestion: async ({ commit }, params) => {
    try {
      commit(types.default.REMOVE_QUESTION, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  setEnabledWidget: async ({ commit }, params) => {
    try {
      commit(types.default.SET_ENABLE_WIDGET, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  setUploadingState: async ({ commit }, params) => {
    commit(types.default.SET_ADD_CONTENT_UI_FLAG, { isUploading: params });
  },
  setIsDoneUploading: async ({ commit }, params) => {
    commit(types.default.SET_ADD_CONTENT_UI_FLAG, { isDoneUploading: params });
  },
  setUploadingStatus: async ({ commit }, params) => {
    commit(types.default.SET_UPLOAD_STATUS, params);
  },
  setFiles: async ({ commit }, params) => {
    try {
      commit(types.default.SET_FILES, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  setText: async ({ commit }, params) => {
    try {
      commit(types.default.SET_TEXT, params);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  saveText: async ({ commit }, params) => {
    try {
      commit(types.default.SAVE_TEXT, params.text);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  getWidgetStatus: async ({ commit }, inboxId) => {
    try {
      const response = await webChannel.getWidgetStatus(inboxId);
      commit(types.default.SET_INBOX_WIDGET_STATUS, response.data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_FILES]($state, files) {
    $state.files = files;
  },
  [types.default.SET_TEXT]($state, text) {
    $state.text = text;
  },
  [types.default.SAVE_TEXT]($state) {
    $state.savedText = $state.text;
  },
  [types.default.SET_INBOX_WIDGET_STATUS]($state, widgetStatus) {
    $state.isWidgetEnabled = widgetStatus;
  },
  [types.default.SET_QUESTIONS]($state, questions) {
    $state.questions = questions;
  },
  [types.default.SAVE_QUESTIONS]($state, questions) {
    const allQuestions = questions.map(question => {
      return {
        ...question,
        saved: true,
      };
    });
    $state.savedQuestions = [...allQuestions];
    $state.questions = [...allQuestions];
  },
  [types.default.ADD_QUESTION]($state, question) {
    $state.questions.unshift(question);
  },
  [types.default.REMOVE_QUESTION]($state, id) {
    $state.questions = $state.questions.filter(question => question.id !== id);
  },
  [types.default.SET_QUESTION_CHANGED]($state, question) {
    const index = $state.questions.findIndex(item => item.id === question.id);
    if (index !== -1) {
      Vue.set($state.questions, index, {
        ...$state.questions[index],
        ...question,
      });
    } else {
      $state.questions.push(question);
    }
  },
  [types.default.UPDATE_QUESTION]($state, question) {
    const index = $state.questions.findIndex(item => item.id === question.id);
    if (index !== -1) {
      Vue.set($state.questions, index, {
        ...$state.questions[index],
        ...question,
      });
    } else {
      $state.questions.push(question);
    }
  },
  [types.default.SET_ADD_CONTENT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_ENABLE_WIDGET]($state, isWidgetEnabled) {
    $state.isWidgetEnabled = isWidgetEnabled;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
