<!-- eslint-disable vue/no-mutating-props -->
<template>
  <modal :show.sync="show" :on-close="onClose">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :header-content-value="messageValue"
    />
    <div class="modal-footer rounded-xl delete-item">
      <div
        class="btn-mila-outline rounded-lg !flex"
        @click="onClose"
      >
        <font-awesome-icon
          v-if="!!rejectIcon"
          :icon="`fa-solid ${rejectIcon}`"
        />
        {{ rejectText }}
      </div>
      <div
        :class="`btn-mila rounded-lg !flex`"
        @click="onConfirm"
      >
        <font-awesome-icon
          v-if="!!confirmIcon"
          :icon="`fa-solid ${confirmIcon}`"
        />
        {{ confirmText }}
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    messageValue: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
    primaryClass: { type: String, default: 'bg-supa-blurple' },
    confirmIcon: { type: String, default: '' },
    rejectIcon: { type: String, default: '' },
  },
};
</script>
<style lang="scss" scoped>
.action-button {
  max-width: var(--space-giga);
}
</style>
