import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { frontendURL } from '../../helper/URLHelper';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
import channelFactory from './settings/inbox/channel-factory';
import { isMobile } from '../../mixins/uiSettings';
const InboxChannel = () => import('./settings/inbox/InboxChannels.vue');
const AddContent = () => import('./settings/inbox/AddContent.vue');
const Publish = () => import('./settings/inbox/Publish.vue');
const Preview = () => import('./settings/inbox/Preview.vue');
const TestMila = () => import('./settings/inbox/TestMila.vue');
const AddAgents = () => import('./settings/inbox/AddAgents.vue');
const FinishSetup = () => import('./settings/inbox/FinishSetup.vue');
const UploadDocuments = () => import('./settings/inbox/UploadDocuments.vue');
const MyContent = () => import('./settings/inbox/MyContent.vue');
const AppContainer = () => import('./Dashboard.vue');
const Suspended = () => import('./suspended/Index.vue');

// Mobile component
const AddContentMobile = () => import('./settings/inbox/AddContentMobile.vue')

export default {
  routes: [
    ...helpcenterRoutes.routes,
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        {
          path: frontendURL('accounts/:accountId/new-inbox'),
          name: 'new_inb',
          component: InboxChannel,
          children: [
            {
              path: '/',
              name: 'home_inbox_new',
              component: () => {
                if(isMobile()) {
                  return import('./settings/inbox/AddContentMobile.vue');
                }
                return import('./settings/inbox/AddContent.vue')
              },
              roles: ['administrator'],
            },
            {
              path: 'preview',
              name: 'home_inbox_preview',
              component: Preview,
              roles: ['administrator'],
            },
            {
              path: 'testmila',
              name: 'home_inbox_test',
              component: TestMila,
              roles: ['administrator'],
            },
            {
              path: ':inbox_id/upload_documents',
              name: 'home_inbox_upload_documents',
              component: UploadDocuments,
              roles: ['administrator'],
            },
            {
              path: 'publish',
              name: 'home_inbox_publish',
              component: Publish,
              roles: ['administrator'],
            },
            {
              path: ':inbox_id/finish',
              name: 'home_inbox_finish',
              component: FinishSetup,
              roles: ['administrator'],
            },
            {
              path: ':sub_page',
              name: 'home_inbox_page_channel',
              component: channelFactory.create(),
              roles: ['administrator'],
              props: route => {
                return { channel_name: route.params.sub_page };
              },
            },
            {
              path: ':inbox_id/agents',
              name: 'home_inbox_add_agents',
              roles: ['administrator'],
              component: AddAgents,
            },
          ],
          roles: ['administrator', 'agent'],
        },
        {
          path: frontendURL('accounts/:accountId/my-content'),
          name: 'my_content',
          component: MyContent,
          roles: ['administrator'],
        },
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...searchRoutes,
        ...notificationRoutes,
      ],
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      roles: ['administrator', 'agent'],
      component: Suspended,
    },
    // {
    //   path: frontendURL('accounts/:accountId/new-inbox'),
    //   name: 'new_inb',
    //   component: InboxChannel,
    //   children: [
    //     {
    //       path: '',
    //       name: 'settings_inbox_new',
    //       component: ChannelList,
    //       roles: ['administrator'],
    //     },
    //     {
    //       path: ':inbox_id/finish',
    //       name: 'settings_inbox_finish',
    //       component: FinishSetup,
    //       roles: ['administrator'],
    //     },
    //     {
    //       path: ':sub_page',
    //       name: 'settings_inboxes_page_channel',
    //       component: channelFactory.create(),
    //       roles: ['administrator'],
    //       props: route => {
    //         return { channel_name: route.params.sub_page };
    //       },
    //     },
    //     {
    //       path: ':inbox_id/agents',
    //       name: 'settings_inboxes_add_agents',
    //       roles: ['administrator'],
    //       component: AddAgents,
    //     },
    //   ],
    //   roles: ['administrator', 'agent'],
    // },
  ],
};
