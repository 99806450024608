<template>
  <transition-group name="toast-slide" tag="div" class="ui-snackbar-container">
    <woot-snackbar
      v-for="snackMessage in snackMessages"
      :key="snackMessage.key"
      :title="snackMessage.title"
      :type="snackMessage.type"
      :message="snackMessage.message"
      :icon="snackMessage.icon"
      :show-loading="snackMessage.showLoading"
    />
  </transition-group>
</template>

<script>
import WootSnackbar from './Snackbar.vue';
import { BUS_EVENTS } from '../../shared/constants/busEvents';

export default {
  components: { WootSnackbar },
  props: {
    duration: {
      type: Number,
      default: 30000,
    },
  },

  data() {
    return {
      snackMessages: [],
    };
  },

  mounted() {
    bus.$on(BUS_EVENTS.SHOW_TOAST, this.onNewToastMessage);
    bus.$on(BUS_EVENTS.HIDE_TOAST, this.onHideToastMessage);
  },
  beforeDestroy() {
    bus.$off(BUS_EVENTS.SHOW_TOAST, this.onNewToastMessage);
    bus.$off(BUS_EVENTS.HIDE_TOAST, this.onHideToastMessage);
  },
  methods: {
    onNewToastMessage(config) {
      const isConfigObject = typeof config === 'object';

      if (isConfigObject) {
        // Updated to allow for custom keys
        // Custom keys added to allow manual removal of toast messages
        config.key = config.key || new Date().getTime();

        this.snackMessages.push({
          key: config.key,
          title: config.title,
          message: config.message,
          type: config.type,
          icon: config.icon,
          showLoading: config.showLoading || false,
        });
      } else {
        this.snackMessages.push({
          key: config.key,
          title: config,
        });
      }

      let duration = this.duration;

      if (config.duration !== undefined) {
        duration = config.duration;
      }

      // Infinite duration
      if (duration <= 0) {
        return;
      }

      window.setTimeout(() => {
        this.onHideToastMessage(config.key);
      }, duration);
    },
    onHideToastMessage(key) {
      this.snackMessages = this.snackMessages.filter(
        snackMessage => snackMessage.key !== key
      );
    },
  },
};
</script>
