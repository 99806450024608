import Vue from 'vue';
import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

const state = {
  unreadCount: 0,
  ongoingCount: 0,
  resolvedCount: 0,
};

export const getters = {
  getStats: $state => ({
    unreadCount: $state.unreadCount,
    ongoingCount: $state.ongoingCount,
    resolvedCount: $state.resolvedCount,
  }),
};

export const actions = {
  // get: fetch tab conversations stats
  get: async ({ commit }, params) => {
    try {
      const response = await ConversationApi.newMeta(params);
      const {
        data: { new_meta: newMeta },
      } = response;
      commit(types.SET_NEW_TAB_STATS, newMeta);
    } catch (error) {
      // Ignore error
    }
  },
  // set: update the tab conversations stats
  set({ commit }, newData) {
    commit(types.SET_NEW_TAB_STATS, newData);
  },
};

export const mutations = {
  [types.SET_NEW_TAB_STATS](
    $state,
    {
      unread_count: unreadCount,
      ongoing_count: ongoingCount,
      resolved_count: resolvedCount,
    } = {}
  ) {
    Vue.set($state, 'unreadCount', unreadCount);
    Vue.set($state, 'ongoingCount', ongoingCount);
    Vue.set($state, 'resolvedCount', resolvedCount);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
