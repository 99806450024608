<template>
  <div>
    <!-- Desktop -->
    <div
      class="flex items-center justify-center h-12 gap-4 px-4 py-3 text-xs text-white banner dark:text-white"
      :class="bannerClasses"
      v-if="!mobile"
    >
      <div class="text-xs font-bold banner-title md:text-base">
        <span
          ><font-awesome-icon icon="fa-solid fa-triangle-exclamation"
        /></span>
        {{ bannerTitle }}
      </div>
      <div class="banner-message">
        <span class="text-xs md:text-base">
          {{ bannerMessage }}
          <span v-if="isPendingEmailVerification" class="text-xs md:text-base">
            Click
            <span class="cursor-pointer text-supa-blurple" @click="onClick">
              here
            </span>
            to resend the verification email.
          </span>
          <a
            v-if="hrefLink"
            :href="hrefLink"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            {{ hrefLinkText }}
          </a>
        </span>
        <div v-if="!isPendingEmailVerification" class="actions">
          <woot-button
            v-if="hasActionButton"
            size="tiny"
            :icon="actionButtonIcon"
            :variant="actionButtonVariant"
            color-scheme="primary"
            class-names="banner-action__button"
            @click="onClick"
          >
            {{ actionButtonLabel }}
          </woot-button>
          <woot-button
            v-if="hasCloseButton"
            size="tiny"
            :color-scheme="colorScheme"
            icon="dismiss-circle"
            class-names="banner-action__button"
            @click="onClickClose"
          >
            {{ $t('GENERAL_SETTINGS.DISMISS') }}
          </woot-button>
        </div>
      </div>
    </div>

    <!-- Mobile -->

    <div
    class="flex items-center justify-around gap-4 px-4 py-4 text-xs text-black h-14 bg-mila-peach100"
    :class="bannerClasses"
    v-if="mobile"
  >
    <div class="text-xs font-bold banner-title md:text-base">
      <span
        ><font-awesome-icon icon="fa-solid fa-triangle-exclamation"
      /></span>
      {{ bannerTitle }}
    </div>
    <div class="banner-message">
      <span class="text-xs md:text-base">
        <span v-if="isPendingEmailVerification" class="text-xs rounded-lg md:text-base btn-mila" @click="onClick">
          Send verification email
        </span>
      </span>
      <div v-if="!isPendingEmailVerification" class="actions">
        <woot-button
          v-if="hasActionButton"
          size="tiny"
          :icon="actionButtonIcon"
          :variant="actionButtonVariant"
          color-scheme="primary"
          class-names="banner-action__button"
          @click="onClick"
        >
          {{ actionButtonLabel }}
        </woot-button>
        <woot-button
          v-if="hasCloseButton"
          size="tiny"
          :color-scheme="colorScheme"
          icon="dismiss-circle"
          class-names="banner-action__button"
          @click="onClickClose"
        >
          {{ $t('GENERAL_SETTINGS.DISMISS') }}
        </woot-button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { isMobile } from '../../mixins/uiSettings';

export default {
  props: {
    isPendingEmailVerification: {
      type: Boolean,
      default: false,
    },
    bannerTitle: {
      type: String,
      default: '',
    },
    bannerMessage: {
      type: String,
      default: '',
    },
    hrefLink: {
      type: String,
      default: '',
    },
    hrefLinkText: {
      type: String,
      default: '',
    },
    hasActionButton: {
      type: Boolean,
      default: false,
    },
    actionButtonVariant: {
      type: String,
      default: '',
    },
    actionButtonLabel: {
      type: String,
      default: '',
    },
    actionButtonIcon: {
      type: String,
      default: 'arrow-right',
    },
    colorScheme: {
      type: String,
      default: '',
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bannerClasses() {
      const classList = [this.colorScheme];

      if (this.hasActionButton || this.hasCloseButton) {
        classList.push('has-button');
      }
      return classList;
    },
    mobile() {
      return isMobile();
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onClickClose(e) {
      this.$emit('close', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  &.primary {
    @apply bg-supa-primary95 dark:bg-supa-primary95 text-supa-greys1;
  }

  &.secondary {
    @apply bg-slate-200 dark:bg-slate-300 text-slate-800 dark:text-slate-800;
    a {
      @apply text-slate-800 dark:text-slate-800;
    }
  }

  &.alert {
    @apply bg-red-500 dark:bg-red-500;
    .banner-action__button {
      @apply bg-red-700 dark:bg-red-700 border-none text-white dark:text-white;

      &:hover {
        @apply bg-red-800 dark:bg-red-800;
      }
    }
  }

  &.warning {
    @apply bg-yellow-500 dark:bg-yellow-500 text-yellow-500 dark:text-yellow-500;
    a {
      @apply text-yellow-500 dark:text-yellow-500;
    }
  }

  &.gray {
    @apply text-black-500 dark:text-black-500;
    .banner-action__button {
      @apply text-white dark:text-white;
    }
  }

  a {
    @apply ml-1 underline text-white dark:text-white text-xs;
  }

  .banner-action__button {
    ::v-deep .button__content {
      @apply whitespace-nowrap;
    }
  }

  .banner-message {
    @apply flex items-center;
  }

  .actions {
    @apply flex gap-1 right-3;
  }
}
</style>
