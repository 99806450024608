<template>
  <span>
    <a
      v-if="!push"
      :href="url"
      :target="target"
      :class="`text-supa-blurple underline ${className}`"
    >
      <slot />
    </a>
    <span
      v-if="push"
      :class="`text-supa-blurple underline ${className}`"
      @click="handleLinkClick"
    >
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    push: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_blank',
    },
    className: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleLinkClick() {
      this.$router.push(this.url);
    },
  },
};
</script>
