<template>
  <div>
    <transition-group
      name="wizard-items"
      tag="div"
      class="flex flex-col"
      :class="classObject"
    >
      <div
        v-for="item in items"
        :key="item.route"
        class="item"
        :class="{ active: isActive(item), over: isOver(item) }"
        @click="checkEnableAndClick(item)"
      >
        <div class="flex items-center">
          <h3
            class="overflow-hidden whitespace-nowrap text-ellipsis leading-tight text-supa-greys1"
          >
            {{ item.title }}
          </h3>
        </div>
        <span class="step">
          {{ items.indexOf(item) + 1 }}
        </span>
        <p class="text-supa-greys2 text-sm">{{ item.body }}</p>
      </div>
    </transition-group>
    <div>
      <div v-if="activeTab === 0" class="bg-[#E6F5FD] p-4 mt-2">
        <div class="font-bold text-base mb-2">
          <font-awesome-icon
            icon="fa-solid fa-lightbulb"
            class="w-4 h-4 mr-2"
          />What links should I add?
        </div>
        <ul class="info-box-list">
          <li>
            Pages on your website or store with information you'd like to train
            Mila on
          </li>
          <li>
            Provide your external help centre homepage link for best results
          </li>
          <li>
            Top-level domains will give the best results (e.g.
            https://myhelpcenter.com rather than https://myhelpcenter.com/home)
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 1" class="bg-[#E6F5FD] p-4 mt-2">
        <div class="font-bold text-base mb-2">
          <font-awesome-icon
            icon="fa-solid fa-lightbulb"
            class="w-4 h-4 mr-2"
          />What files should I add?
        </div>
        <ul class="info-box-list">
          <li>Common FAQs</li>
          <li>Customer Help Guides</li>
          <li>Email marketing newsletters</li>
          <li>TXT, PDF, XML</li>
          <li>Words document</li>
        </ul>
        <div class="text-sm">
          Not sure where to start?
          <supa-link
            :class-name="'text-supa-greys1 text-sm'"
            :url="`https://calendar.app.google/6H8fNWVhV9G9RfnK7`"
          >
            <span>Book a setup call with us here</span>
          </supa-link>
        </div>
      </div>
      <div v-if="activeTab === 2" class="bg-[#E6F5FD] p-4 mt-2">
        <div class="font-bold text-base mb-2">
          <font-awesome-icon
            icon="fa-solid fa-lightbulb"
            class="w-4 h-4 mr-2"
          />What text can I add?
        </div>
        <ul class="info-box-list">
          <li>Anything about your business, your products and services</li>
          <li>
            If you have an existing FAQ page on your website, feel free to copy
            and paste the text here and we will do the rest
          </li>
          <li>Anything else?</li>
        </ul>
      </div>
      <div v-if="activeTab === 3" class="bg-[#E6F5FD] p-4 mt-2">
        <div class="font-bold text-base mb-2">
          <font-awesome-icon
            icon="fa-solid fa-lightbulb"
            class="w-4 h-4 mr-2"
          />What Q&As should I add?
        </div>
        <ul class="info-box-list">
          <li>
            Start with the most frequently asked questions by your customers or
            users
          </li>
          <li>
            Think about specific edge cases or questions that will help Mila
            answer more accurately
          </li>
          <li>
            If you’re adding Mila to an online store, be sure to cover topics
            such as your Returns&nbsp;&&nbsp;Exchanges policies
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { frontendURL } from '../../helper/URLHelper';
import { mapGetters } from 'vuex';

export default {
  mixins: [globalConfigMixin],
  props: {
    isFullwidth: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    enableClick: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    classObject() {
      return 'w-full';
    },
    activeIndex() {
      return this.items.findIndex(i => i.route === this.$route.name);
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
  },
  methods: {
    checkEnableAndClick(item) {
      if (!this.enableClick) {
        this.$emit('prompt-unsaved', item);
      } else {
        this.handleClick(item);
      }
    },
    handleClick(item) {
      if (this.isActive(item) || !item.route) {
        return;
      }

      const isPortalRoute =
        item.route.startsWith('portal_') ||
        item.route.startsWith('new_portal_');

      // Help center links also uses the same wizard component
      // Disable the click event for help center links
      if (item.route && isPortalRoute) {
        return;
      }

      window.location.href = frontendURL(
        `accounts/${this.accountId}/new-inbox/${item.path}`
      );
    },
    isActive(item) {
      return this.items.indexOf(item) === this.activeIndex;
    },
    isOver(item) {
      return this.items.indexOf(item) < this.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-box-list li {
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
