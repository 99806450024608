<template>
  <div>
    <div class="ui-snackbar relative" :class="`ui-snackbar--${type}`">
      <woot-button
        color-scheme="secondary"
        icon="dismiss"
        variant="clear"
        class="absolute top-0 right-0"
        @click="close"
      ></woot-button>
      <div class="flex flex-col gap-2 relative">
        <div class="ui-snackbar-title flex flex-row gap-2 items-center">
          <spinner v-if="showLoading" color-scheme="primary" />
          <component :is="icon" />
          <div>{{ title }}</div>
        </div>
        <component :is="message" />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import { BUS_EVENTS } from '../../shared/constants/busEvents';
export default {
  components: {
    Spinner,
  },
  props: {
    title: { type: String, default: '' },
    type: {
      type: String,
      default: 'info',
    },
    message: {
      type: Object,
      default: () => {},
    },
    icon: {
      type: Object,
      default: () => {},
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    close() {
      window.bus.$emit(BUS_EVENTS.HIDE_TOAST, this.$vnode.key);
    },
  },
};
</script>
